<template>
  <div>
    <div :class="{'opacity-30': !getSaveLink.password_protected}" v-if="toggle">
      <p class="text !text-[#202324] font-bold">Password Protected</p>
      <FloatingLabelInput
        id="password"
        v-model="getSaveLink.password"
        type="text"
        :disabled="!getSaveLink.password_protected"
        label="Password"
        customClass="mt-6 h-[3.5rem] bg-[#F4F6FA]"
        :errorType="passwordProtectedValidations.password || passwordProtectedValidations.passwordLength ||passwordProtectedValidations.passwordValid  ? 'danger' : ''"
      >
        <template v-slot:error_message>
          <InputFieldMessage v-if="passwordProtectedValidations.password" :message="passwordProtectedMessages.password"></InputFieldMessage>
          <InputFieldMessage v-else-if="passwordProtectedValidations.passwordValid" :message="passwordProtectedMessages.passwordValid"></InputFieldMessage>
        </template>
      </FloatingLabelInput>
      <URLInputFloatingV2
        class="mt-[0.75rem]"
        id="upload_password_protected_url"
        v-model="getSaveLink.password_protected_logo"
        :validations="passwordProtectedValidations"
        :disabled="!getSaveLink.password_protected"
        type="text"
        label="Upload Password Protected Image URL"
        customClass="h-[3.5rem] bg-[#F4F6FA]"
        :errorType="passwordProtectedValidations.url || passwordProtectedValidations.urlLength || passwordProtectedValidations.urlValid  ? 'danger' : ''"
      >
        <template v-slot:error_message>
          <InputFieldMessage v-if="passwordProtectedValidations.url" :message="passwordProtectedMessages.url"></InputFieldMessage>
          <InputFieldMessage v-else-if="passwordProtectedValidations.urlLength" :message="passwordProtectedMessages.urlLength"></InputFieldMessage>
          <InputFieldMessage v-else-if="passwordProtectedValidations.urlValid" :message="passwordProtectedMessages.urlValid"></InputFieldMessage>
        </template>
      </URLInputFloatingV2>
      <ImageUploadV2
        customClass="mt-[0.75rem] h-[12rem]"
        :image="getSaveLink.password_protected_logo"
        :disabled="!getSaveLink.password_protected"
        @click="getSaveLink.password_protected_logo = $event"
      ></ImageUploadV2>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  passwordProtectedMessages,
  passwordProtectedValidations,
} from '@/common/attributes'

export default {
  props: ['toggle'],
  computed: {
    ...mapGetters([
      'getSaveLink',
      'getLoaders'
    ])
  },
  data() {
    return {
      passwordProtectedValidations: passwordProtectedValidations,
      passwordProtectedMessages: passwordProtectedMessages
    }
  },
  components: {
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    ImageUploadV2: () => import('@/ui/ui-kit/v2/ImageUploadV2.vue'),
  },
  methods: {
    validatePassword() {
      let validate = false
      if (this.getSaveLink.password_protected) {
        this.passwordProtectedValidations.password = this.requiredCheck(this.getSaveLink.password)
        this.passwordProtectedValidations.passwordValid = this.getSaveLink.password && (this.getSaveLink.password.length < 6 || this.getSaveLink.password.length > 12)
        this.passwordProtectedValidations.url = this.requiredCheck(this.getSaveLink.password_protected_logo)
        this.passwordProtectedValidations.urlLength = !this.maxLength(this.getSaveLink.password_protected_logo, 500)

        if(this.getSaveLink.password_protected_logo && this.getSaveLink.password_protected_logo.length) {
          this.getSaveLink.password_protected_logo = this.urlConcatenation(this.getSaveLink.password_protected_logo)
          this.passwordProtectedValidations.urlValid = !this.isValidURL(this.getSaveLink.password_protected_logo)
        }

        validate = Object.keys(this.passwordProtectedValidations).every(k => this.passwordProtectedValidations[k] == false)
      }
      return validate
    }
  },
  watch: {
    'getSaveLink.password' (value) {
      console.log(value)
      this.passwordProtectedValidations.password = this.requiredCheck(value)
      this.passwordProtectedValidations.passwordValid = value && (value.length < 6 || value.length > 12)
    },
    'getSaveLink.password_protected_logo' (value) {
      this.passwordProtectedValidations.url = this.requiredCheck(value)
      this.passwordProtectedValidations.urlLength = !this.maxLength(value, 500)
      this.passwordProtectedValidations.urlValid = !this.isValidURL(value)
    }
  }
}
</script>
